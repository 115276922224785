/*
 * Translated default messages for the jQuery validation plugin.
 * Locale: SI (Slovenian)
 */
$.extend($.validator.messages, {
  required: "Polje je obvezno.",
  remote: "Vpis v tem polju ni v pravi obliki.",
  email: "Prosimo, vnesite pravilni e-poštni naslov.",
  url: "Prosimo, vnesite pravi URL.",
  date: "Prosimo, vnesite pravi datum.",
  dateISO: "Prosimo, vnesite pravi datum (ISO).",
  number: "Prosimo, vnesite pravo številko.",
  digits: "Prosimo, vnesite samo številke.",
  creditcard: "Prosimo, vnesite pravo številko kreditne kartice.",
  equalTo: "Prosimo, ponovno vnesite enako vsebino.",
  extension: "Prosimo, vnesite vsebino z pravo končnico.",
  maxlength: $.validator.format("Presegli ste maksimalno dolžino {0} znakov."),
  minlength: $.validator.format("Prosimo, vnesite vsaj {0} znakov."),
  rangelength: $.validator.format("Prosimo, vnesite od {0} do {1} znakov."),
  range: $.validator.format("Prosimo, vnesite vrednost med {0} in {1}."),
  max: $.validator.format("Prosimo, vnesite vrednost manjšo ali enako {0}."),
  min: $.validator.format("Prosimo, vnesite vrednost večjo ali enako {0}."),
});

/* Extend validator methods */

$.validator.addMethod(
  "phone-number",
  function(value, element) {
    // Check MSISDN format
    if (/^386[1-9][0-9]{7}$/.test(value)) {
      return true;
    }
    // Check number format
    else if (/^0[1-9][0-9]{7}$/.test(value)) {
      return true;
    }

    return false;
  },
  "Prosimo vnesite pravilno telefonsko številko. Primer: 041410410 ali 38641410410"
);

$.validator.addMethod(
  "postal-code",
  function(value, element) {
    // Check postal code format
    if (/^[1-9][0-9]{3}$/.test(value)) {
      return true;
    }

    return false;
  },
  "Prosimo vnesite pravilno poštno številko."
);

$.validator.addMethod(
  "vat",
  function(value, element) {
    if (/^[0-9]{8}$/.test(value)) {
      return true;
    }

    return false;
  },
  "Prosimo vnesite pravilno davčno številko."
);

$.validator.addMethod(
    "vat-or-empty",
    function(value, element) {
        if (/^[0-9]{8}$|^$/.test(value)) {
            return true;
        }
        return false;
    },
    "Prosimo vnesite pravilno davčno številko."
);

$.validator.addMethod(
  "special-chars",
  function(value, element) {
    if (/^[a-zA-Z0-9]+$/.test(value)) {
      return true;
    }

    return false;
  },
  "Prosim vnesite samo alfanumerične ali abecedne znake."
);
